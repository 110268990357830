<template>
  <div class="fb-list">
    <ul class="list">
      <li
        class="item"
        @click="toComponent('fb-info', item.id)"
        :key="index"
        v-for="(item, index) in dataList"
      >
        <p class="title">
          <span class="status finish" v-if="item.orderStatus == 3">已解决</span>
          <span
            class="status wait"
            v-if="item.orderStatus == 0 || item.orderStatus == 1"
            >待解决</span
          >
          <span class="status work" v-if="item.orderStatus == 2">解决中</span>
          <span class="statusWords">{{ item.description }}</span>
        </p>
        <p class="answerWrap">
          <span class="answer">{{
            Boolean(item.messages[0])
              ? item.messages[0].content
              : "暂无客服回复"
          }}</span>
        </p>
      </li>
      <p class="tips" v-if="dataList.length <= 0">暂无反馈记录~</p>
    </ul>
    <button v-if="canSubmit" class="submit" @click="toComponent('fb-type')">
      提交工单
    </button>
  </div>
</template>

<script>
// 需要哪些模块按需引入
import { toRefs, reactive, onMounted, getCurrentInstance } from "vue";
import { getWordOrders, knowledgeGroupList } from "_API_/api.services";
import { setStore } from "@/utils/store";
export default {
  setup() {
    const state = reactive({
      iccid: "", //iccid卡
      dataList: [], //工单列表
      canSubmit: false //按钮展示
    });
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    // 路由 this.$router
    const { $tool: tool, $store: store, $router: router } = ctx;
    const methods = {
      //路由跳转
      toComponent(name, type) {
        switch (name) {
          case "fb-type":
            router.push({
              name
            });
            break;
          case "fb-info":
            router.push({
              name,
              query: {
                orderId: type
              }
            });
            break;
          default:
            break;
        }
      },
      //获取工单列表
      getList() {
        getWordOrders({
          card: this.iccid
        }).then(({ data: res }) => {
          if (res.code == 0) {
            state.dataList = res.data;
            state.canSubmit = Boolean(res.canSubmit);
            return;
          }
          tool.toast(
            {
              msg: res.msg
            },
            () => {}
          );
        });
      },
      //获取工单群列表
      getTypeList() {
        let conf = new FormData();
        conf.append("groupType", 1);
        conf.append("userType", 1);
        knowledgeGroupList(conf).then(({ data: res }) => {
          if (res.code != 0) {
            tool.toast(
              {
                msg: res.msg
              },
              () => {}
            );
            return;
          }
          // 存储用户信息到sessionStorage
          setStore({
            name: "typeList",
            content: JSON.parse(JSON.stringify(res.data)),
            type: "session"
          });
        });
      }
    };
    onMounted(() => {
      //获取用户iccid  工单查询页进入 或  公众号查询页进入
      state.iccid =
        store.state.user.queryIccid || store.state.user.userInfo.iccid;
      ctx.getList();
      ctx.getTypeList();
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/theme/index";
.fb-list {
  height: 100vh;
  background: #fff;
  overflow: hidden;
}
.list {
  .item {
    padding: 20px;
    font-size: 26px;
    color: #3e3e3e;
    border-bottom: 1px solid #ccc;
    p.title {
      height: 60px;
      line-height: 60px;
      overflow: hidden;
    }
    .status {
      display: inline-block;
      width: 14%;
      text-align: center;
      color: #fff;
      height: 40px;
      line-height: 40px;
      border-radius: 6px;
      font-size: 16px;
      text-indent: 0;
      vertical-align: middle;
    }
    .status.finish {
      content: "已解决";
      background: lightgreen;
    }
    .status.wait {
      content: " 待解决";
      background: deepskyblue;
    }
    .status.work {
      content: " 解决中";
      background: #ef4f4f;
    }
    .statusWords {
      display: inline-block;
      width: 84%;
      height: 60px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      vertical-align: middle;
    }
    .answerWrap {
      background: #efefef;
      padding: 20px;
      margin-top: 4px;
      border-radius: 10px;
      .answer {
        border-radius: 4px;
        max-height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }
    }
  }
}

.submit {
  width: 96%;
  height: 60px;
  @include background_color("background_color1");
  color: #fff;
  text-align: center;
  line-height: 16px;
  display: block;
  border: none;
  border-radius: 10px;
  position: fixed;
  bottom: 4px;
  left: 2%;
}
.tips {
  font-size: 16px;
  color: #c7c7cc;
  text-align: center;
  margin-top: 10px;
}
.pop {
  height: 50px;
  background: #fff;
  p {
    line-height: 30px;
    font-size: 16px;
    text-align: center;
    padding: 0 3%;
    color: #c7c7cc;
  }
  .item {
    height: 20px;
    line-height: 20px;
    font-size: 16px;
    text-align: center;
    border-top: 1px solid #c0c0c0;
  }
  .judge {
    background: red;
    color: #fff;
  }
}
</style>
